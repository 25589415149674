import React from  'react'

const Popup = ({styleProps}) => {

    return (
        <div id='popup' className='overlay' style={styleProps}>
  <div id='popup-box' >
    <h2 className="message-title">Message Submitted</h2>
    <div className="content">
      Thank you for your message, I will get back to you as soon as I can!
    </div>
  </div>
</div>
    )
}

export default Popup
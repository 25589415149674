import React from 'react'
import '../../scss/Portfolio.scss'
import ScrollAnimation from 'react-animate-on-scroll'

const PortfolioItem = ({
  url,
  github,
  webp,
  png,
  alt,
  title,
  builtWith,
  text,
  direction,
}) => {


  return (
    <ScrollAnimation animateIn='fadeIn' animateOnce={true} className='col-12 col-xl-10 portfolio-item'>
      <div className='row justify-content-center'>
        <a href={url} className='col-12 col-lg-6'>
          <picture>
            <source srcSet={webp} alt={alt} className='portfolio-img'/>
            <source srcSet={png} alt={alt} className='portfolio-img'/>
            <img srcSet={png} alt={alt} className='portfolio-img'/>
          </picture>
        </a>
        <div className='row portfolio-textbox'>
          <h2>
            <a href={url} style={{ color: 'inherit' }}>
              {title}
            </a>
          </h2>
          {github && (
            <div>
              Github repo: <a href={github}>{github}</a>
            </div>
          )}
          <p className='fw-bolder'>Built with: {builtWith}</p>
          <p>{text}</p>
          <a href={url} className='col-12'>
            View Project
          </a>
        </div>
        </div>
    </ScrollAnimation>
  )
}

export default PortfolioItem

import React, { Fragment, useState } from 'react'
import '../scss/Contact.scss'
import axios from 'axios'
import ScrollAnimation from 'react-animate-on-scroll'
import deploymentConfig from '../config/deploymentConfig'

const Contact = ({ parallax, contactRef, popupBox }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  function handleSubmit(formData) {
    try {
      axios.post(`${deploymentConfig().apiUrl}api/send`, formData)
      popupBox()
      console.log('message sent')
    } catch (error) {
      console.log('message could not be sent')
    }
  }

  return (
    <Fragment>
      <div ref={contactRef} />
      <section
        style={{ backgroundImage: `url(${parallax})` }}
        className='container-fluid mt-5 parallax-2'
      >
        <ScrollAnimation animateIn='fadeIn' className='row justify-content-center'>
          <div className='col-12 col-lg-10 mb-5'>
            <div className='row justify-content-center align-items-center'>
            <div className='col-12 m-5 title'>
              <h1>Contact Me</h1>
            </div>
            <p className='col-12'>
              For any queries you may have, feel free to message me at
              <a href='mailto:contact@ieuanquinlan.com'> contact@ieuanquinlan.com</a> or on the contact form below and I will
              be in touch!
            </p>

            <div className='col-10 col-md-8 col-lg-6 col-xl-4 mt-3'>
              <form
                className='row justify-content-center'
                name='contact-form'
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(formData)
                  setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                  })
                }}
              >
                <input
                  type='text'
                  className='form-input'
                  id='name'
                  placeholder='Name'
                  name='name'
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />

                <input
                  type='email'
                  className='form-input'
                  id='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />

                <input
                  type='text'
                  className='form-input'
                  id='subject'
                  placeholder='Subject'
                  name='subject'
                  value={formData.subject}
                  onChange={(e) =>
                    setFormData({ ...formData, subject: e.target.value })
                  }
                  required
                />

                <textarea
                  className='form-input'
                  rows='5'
                  placeholder='Message (max 250 characters)'
                  name='message'
                  value={formData.message}
                  maxLength='250'
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                ></textarea>

                <button
                  className='submit-button'
                  id='submit-btn'
                  type='submit'
                  value='Submit'
                >
                  <div className='submit-icons'>
                    <div className='fa fa-paper-plane'/>
                    <div className='send-text'>Send</div>
                  </div>
                </button>
              </form>
            </div>

            <div className='col-12 col-md-6 col-lg-6 col-xl-4'>
            <div className='row'>
                <ul className='col-12'>
                  <li className='row justify-content-center m-2'>
                    <div className='col-2 fa fa-map-marker fa-2x' />
                      <p className='col-6 col-lg-4 m-2'>Maidstone | UK
                      </p>
                  </li>

                  <li className='row justify-content-center m-2'>
                    <div className='col-2 fa fa-phone fa-2x'/>
                      <p className='col-6 col-lg-4 m-2'>
                        <a href='tel:44-7463-840510' title='Give me a call'>+44 7474103153
                        </a>
                      </p>
                  </li>

                  <li className='row justify-content-center m-2'>
                    <div className='col-2 fa fa-envelope fa-2x'/>
                      <p className='col-6 col-lg-4 m-2'>
                        <a href='mailto:#' title='Send me an email'>contact@ieuanquinlan.com
                        </a>
                      </p>
                  </li>
                </ul>

                <ul className='col-12 social-media-list'>
                  <li>
                    <a
                      href='https://github.com/ieuanquinlan'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-github' aria-hidden='true'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://github.com/ieuan-westerman'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-linkedin' aria-hidden='true'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://codepen.io/ieuan-quinlan'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-codepen' aria-hidden='true'></i>
                    </a>
                  </li>
                </ul>
              </div>
              </div>
              </div>
              </div>
        </ScrollAnimation>
      </section>
    </Fragment>
  )
}

export default Contact

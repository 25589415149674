import React, { Fragment, useState, useEffect } from 'react'
import '../scss/Navbar.scss'

const Navbar = ({lightDarkMode, sunStyle, moonStyle, scrollToEl, aboutRef, portfolioRef, contactRef}) => {
  const [hovered, setHovered] = useState(false)
  const [headerSolid, setHeaderSolid] = useState('')
  const [scrolled, setScrolled] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset
      if (currentPosition > 150) {
        setHeaderSolid('header-solid')
        setScrolled(true)
      }
      else {
        setHeaderSolid('')
        setScrolled(false)
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
    }
    window.addEventListener('scroll', onScroll)
    return window.addEventListener('scroll', onScroll)
  }, [scrollTop])

  useEffect(() => {
    if (hovered && !scrolled) {
      setHeaderSolid('header-solid')
    } else if (scrolled) {
      setHeaderSolid('header-solid')
    } else setHeaderSolid('')
  }, [hovered, scrolled])

  return (
    <Fragment>
      <nav id='header-nav'></nav>

      <header
        className={`header ${headerSolid}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <a href='#header-nav' className='logo'>
          Ieuan Quinlan
        </a>
        <input className='menu-btn' type='checkbox' id='menu-btn' />
        <label className='menu-icon' htmlFor='menu-btn'>
          <span className='navicon'></span>
        </label>
        <ul className='menu'>
          <ul className='menu-buttons'>
            <li id='button-about'>
              <div onClick={()=>scrollToEl(aboutRef)}>About</div>
            </li>
            <li id='button-portfolio'>
              <div onClick={()=>scrollToEl(portfolioRef)}>Portfolio</div>
            </li>
            <li id='button-contact'>
              <div onClick={()=>scrollToEl(contactRef)}>Contact</div>
            </li>
          </ul>
          <div className='dark-mode'>
            <label>
              <input id='dark-mode-btn' type='checkbox' onClick={()=> lightDarkMode()}/>
              <span className='slider'>
                <i style={sunStyle} className='fa fa-moon-o moon'></i>
                <i style={moonStyle} className='fa fa-sun-o sun'></i>
              </span>
            </label>
          </div>
        </ul>
      </header>
    </Fragment>
  )
}

export default Navbar

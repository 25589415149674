import React, {Fragment, useState, useEffect} from 'react'
import ScrollAnimation from 'react-animate-on-scroll'


const Title = ({parallax, scrollToEl, contactRef}) => {

    const [scrollTop, setScrollTop] = useState(0)

    const opacity = 1 - window.pageYOffset / 400

    useEffect(() => {
        function onScroll() {
          let currentPosition = window.pageYOffset
          setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
          
        }
        window.addEventListener('scroll', onScroll)
        return window.addEventListener('scroll', onScroll)
      }, [scrollTop])

    return(
    <Fragment>
    <div style={{backgroundImage:`url(${parallax})`}} className="parallax-1">
    
<section style={{opacity:`${opacity}`}} className='caption'>
<ScrollAnimation animateIn='fadeIn'>
  <div className="title-shared">
    <h1>
    Ieuan Quinlan
      </h1>
    </div>
  <div className="title-shared">
    <h3>
    Web Developer
    </h3>
    </div>
      <div id="button-contact-scroll" onClick={()=>scrollToEl(contactRef)}>
    <h3>
    Contact Me
    </h3>
      </div>
      </ScrollAnimation>
</section>

</div>
</Fragment>
    )
}

export default Title
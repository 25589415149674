import React, { Fragment } from 'react'
import '../scss/About.scss'
import Skill from './Components/Skill'
import ScrollAnimation from 'react-animate-on-scroll'
import HTMLpng from '../media/images/html.png'
import HTMLWebp from '../media/images/html.webp'
import csspng from '../media/images/css3.png'
import cssWebp from '../media/images/css3.webp'
import sasspng from '../media/images/sass.png'
import sassWebp from '../media/images/sass.webp'
import JSpng from '../media/images/JS.png'
import JSWebp from '../media/images/JS.webp'
import reactpng from '../media/images/react.png'
import reactWebp from '../media/images/react.webp'
import reduxpng from '../media/images/redux.png'
import reduxWebp from '../media/images/redux.webp'
import nodepng from '../media/images/node.png'
import nodeWebp from '../media/images/node.webp'
import expresspng from '../media/images/express.png'
import expressWebp from '../media/images/express.webp'
import mongodbpng from '../media/images/mongodb.png'
import mongodbWebp from '../media/images/mongodb.webp'
import wordpresspng from '../media/images/wordpress.png'
import wordpressWebp from '../media/images/wordpress.webp'
import jquerypng from '../media/images/jquery.png'
import jqueryWebp from '../media/images/jquery.webp'
import portraitWebp from '../media/images/portrait-min.webp'
import portraitJpg from '../media/images/portrait-min.jpg'
import webpackpng from '../media/images/webpack.png'
import webpackWebp from '../media/images/webpack.webp'
import photoshoppng from '../media/images/photoshop.png'
import photoshopWebp from '../media/images/photoshop.webp'
import gitpng from '../media/images/git.png'
import gitWebp from '../media/images/git.webp'

const About = ({ aboutRef }) => {
	return (
		<Fragment>
			<div className='ref-div' ref={aboutRef} />
			<section className='container mt-5'>
				<ScrollAnimation
					animateIn='fadeIn'
					className='row justify-content-center'
				>
					<div className='col-12 title'>
						<h1>A little bit about me</h1>
					</div>
					<div className='row align-items-center'>
						<div className='col-12 col-lg-7'>
							<div className='row'>
								<div className='col-12 p-4'>
									<p>
										My name is Ieuan Quinlan, I am a front end developer
										currently based in Maidstone, United Kingdom. My main focus
										is front-end development with React, I also have experience
										working with the full MERN stack. Technologies I have worked
										with include:
									</p>
								</div>
								<div className='col-12'>
									<div className='row'>
										<Skill skill='HTML' webp={HTMLWebp} png={HTMLpng} />
										<Skill skill='CSS' webp={cssWebp} png={csspng} />
										<Skill skill='Sass' webp={sassWebp} png={sasspng} />
										<Skill skill='Javascript' webp={JSWebp} png={JSpng} />
										<Skill skill='React' webp={reactWebp} png={reactpng} />
										<Skill skill='Redux' webp={reduxWebp} png={reduxpng} />
										<Skill skill='Node.js' webp={nodeWebp} png={nodepng} />
										<Skill
											skill='Express'
											webp={expressWebp}
											png={expresspng}
										/>
										<Skill
											skill='MongoDB'
											webp={mongodbWebp}
											png={mongodbpng}
										/>
										<Skill
											skill='Wordpress'
											webp={wordpressWebp}
											png={wordpresspng}
										/>
										<Skill skill='JQuery' webp={jqueryWebp} png={jquerypng} />
										<Skill
											skill='Photoshop'
											webp={photoshopWebp}
											png={photoshoppng}
										/>
										<Skill
											skill='Webpack'
											webp={webpackWebp}
											png={webpackpng}
										/>
										<Skill
											skill='Git'
											webp={gitWebp}
											png={gitpng}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='col-12 col-lg-5'>
							<picture className='row justify-content-center'>
								<source
									srcSet={portraitWebp}
									type='image/webp'
									className='col-8'
									alt='portrait'
								/>
								<source
									srcSet={portraitJpg}
									type='image/jpg'
									className='col-8'
									alt='portrait'
								/>
								<img
									src={portraitJpg}
									datasrc={portraitJpg}
									className='col-8'
									alt='portrait'
								/>
							</picture>
						</div>
					</div>
				</ScrollAnimation>
			</section>
		</Fragment>
	)
}

export default About

import React, {Fragment, useState, useEffect, useRef} from 'react'
import Navbar from './Layout/Navbar'
import Title from './Main/Title'
import About from './Main/About'
import Portfolio from './Main/Portfolio'
import Contact from './Main/Contact'
import Footer from './Layout/Footer'
import Popup from './Layout/Popup'
import mountains from './media/images/mountains.jpg'
import pinkSky from './media/images/pink-sky.jpg'
import aurora from './media/images/aurora.jpg'
import moonSky from './media/images/moon-sky.jpg'
import './scss/App.scss'
import 'animate.css/animate.min.css'

const App = () => {

const [darkMode, setDarkMode] = useState(false)
const [popupStyle, setPopupStyle] = useState({
  visibility: 'hidden',
  opacity: 0
})

const sunStyle = darkMode ? {opacity: '1', transition:'ease-in 0.5s'} : {opacity: '0', transition:'ease-in 0.5s'}
const moonStyle = darkMode ? {opacity: '0', transition:'ease-in 0.5s'} : {opacity: '1', transition:'ease-in 0.5s'}
const parallax1 = darkMode ? aurora : mountains
const parallax2 = darkMode ? moonSky : pinkSky
const aboutRef = useRef(null)
const portfolioRef = useRef(null)
const contactRef = useRef(null)

function lightDarkMode() {
    setDarkMode(prevState => prevState = !darkMode)
}
useEffect(() => {
  if(darkMode) {
    document.documentElement.setAttribute('data-theme', 'dark')
  }
  else document.documentElement.setAttribute('data-theme', 'light')
}, [darkMode])

const scrollToEl = (ref) =>{
  window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'})
}

function popupBox() {
  setPopupStyle({
    visibility: 'visible',
    opacity: 1
  })
  setTimeout(() => {
    setPopupStyle({
      visibility: 'hidden',
      opacity: 0
    })
  }, 4000);
}

  return (
    <Fragment>
   <Navbar lightDarkMode={lightDarkMode} sunStyle={sunStyle} moonStyle={moonStyle} scrollToEl={scrollToEl} aboutRef={aboutRef} portfolioRef={portfolioRef} contactRef={contactRef}/>
   <Title parallax={parallax1} scrollToEl={scrollToEl} contactRef={contactRef}/>
   <Popup styleProps={popupStyle}/>
   <About aboutRef={aboutRef}/>
   <Portfolio portfolioRef={portfolioRef}/>
   <Contact parallax={parallax2} contactRef={contactRef} popupBox={popupBox}/>

   <Footer/>
   </Fragment>
  )
}

export default App

import React from 'react'

const Skill = ({webp, png, skill}) => {
    return (
        <div className="col-4 col-lg-3">
          <div className='row text-center'>
          <picture>
            <source srcSet={webp} type="image/webp" alt={skill}/>
            <source srcSet={png} type="image/png" alt={skill}/>
            <img src={png} alt={skill}/>
          </picture>
      <p className='p-3'>{skill}</p>
      </div>
      </div>
    )
}

export default Skill
import React, {Fragment} from 'react'
import '../scss/Portfolio.scss'
import ScrollAnimation from 'react-animate-on-scroll'
import PortfolioItem from './Components/PortfolioItem'
import kerryDavidsonWebp from '../media/images/kerrydavidson.webp'
import kerryDavidsonPng from '../media/images/kerrydavidson.png'
import diamondbriteWebp from '../media/images/diamondbrite.webp'
  import diamondbritePng from '../media/images/diamondbrite.png'
  import webappWebp from '../media/images/diamondbrite-in-action.webp'
  import webappPng from '../media/images/diamondbrite-in-action.png'
  import THDFWebp from '../media/images/THDF_screenshot.webp'
  import THDFPng from '../media/images/THDF_screenshot.png'
  import calotrackWebp from '../media/images/calotrack.webp'
  import calotrackPng from '../media/images/calotrack.png'
  import devconnectorWebp from '../media/images/devconnector.webp'
  import devconnectorPng from '../media/images/devconnector.png'

const Portfolio = ({portfolioRef}) => {


    return (
      <Fragment>
        <div className='ref-div' ref={portfolioRef}/>
<section className='container-fluid mt-5'>
  <div className='row'>
  <ScrollAnimation animateIn='fadeIn' animateOnce={true} className='col-12'>
    <div className='title'>
      <h1>My Work</h1>
    </div>
    </ScrollAnimation>
    <PortfolioItem 
    url='https://kerry-davidson.co.uk'
    webp={kerryDavidsonWebp}
    png={kerryDavidsonPng}
    alt='Kerry Davidson Counselling'
    title='Kerry Davidson Counselling'
    builtWith='Wordpress'
    text='Website created for Kerry Davidson, a mental health counsellor based in Medway to assist her with promoting her counselling services. The site was created on Wordpress with a custom built theme.'
    />
  <PortfolioItem 
    url='https://diamondbrite.co.uk'
    webp={diamondbriteWebp}
    png={diamondbritePng}
    alt='Diamondbrite.co.uk'
    title='Diamondbrite.co.uk'
    builtWith='Wordpress'
    text='Company site for Jewelultra Ltd, a chemical manufacturer based in the UK. I redeveloped and redesigned the front end to a more modern look including much of the artwork displayed on the site, with the aim of simplifying and enhancing the user experience, allowing users to more easily navigate their way and better understand the company, products and brands.'
    />
    <PortfolioItem 
    url='https://diamondbrite.co.uk/diamondbrite-in-action/'
    webp={webappWebp}
    png={webappPng}
    alt='Diamondbrite in Action'
    title='Diamondbrite in Action'
    builtWith='React.js'
    text='An interactive web app built on for Jewelultra Ltd, a chemical manufacturer based in the UK. The web-based app showcases the main features of the Diamondbrite paint protection manufactured by Jewelultra, and was built to assist dealers and sales representatives to demonstrate to customers the unique benefits of the product.'
    />
  <PortfolioItem 
    url='https://calotrack.herokuapp.com/'
    github='https://github.com/ieuanquinlan/calotrack'
    webp={calotrackWebp}
    png={calotrackPng}
    alt='Calotrack'
    title='Calotrack'
    builtWith='MERN (React.js Frontend, Node.js/Express/MongoDB backend)'
    text='Calotrack is a nutritional data logging app which allows users to create an account and log their daily nutritional intakes. Generic login - username: calotrack@calotrack.com - password: calotrack'
    />
    <PortfolioItem 
    url='https://dev-connector-ieuan-quinlan.herokuapp.com/'
    github='https://github.com/ieuanquinlan/dev-connector'
    webp={devconnectorWebp}
    png={devconnectorPng}
    alt='Dev Connector'
    title='Dev Connector'
    builtWith='MERN (React.js Frontend + Redux, Node.js/Express/MongoDB backend)'
    text='A social media app created with the Udemy course "MERN Stack Front To Back", which allows users to log in, add, like and comment on posts and create their own personal developer profile.'
    />
    <PortfolioItem 
    url='http://tomhanksdefenceforce.com/'
    webp={THDFWebp}
    png={THDFPng}
    alt='Wordpress Site'
    title='Wordpress Site - Podcast'
    builtWith='Wordpress'
    text='A podcast site built in Wordpress allowing users to navigate through episodes by topic and date. Site also features regular blog posts by the creators of the show, and has been set up to allow them to update the site with new content as and when is needed.'
    />
    </div>
  </section>
  </Fragment>
    )
}

export default Portfolio